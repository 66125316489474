<template>
  <div class="row">
    <div class="col-12 col-md-5 offset-md-1 mt-md-4">
      <form action="" novalidate>
        <div :class="$style.wrapper">
          <div :class="$style.block">
            <Heading type="h2">Hoe wil je testen?</Heading>
            <RadioRows
                ref="testType"
                :list="getAvailableLocations()"
                :selected="currentLocationValue(form.isHomeTest)"
                :is-error="$v.form.isHomeTest?.$error ?? false"
                @change="handleHomeChange"
                name="homeType"
            />
          </div>

          <div :class="$style.block">
            <Heading type="h2">Persoonsgegevens</Heading>
            <span class="text-primary">Let op: Anoniem testen niet mogelijk</span>
            <FieldGroupComponent
                title="Geslacht (zoals geregistreerd)"
                :class="[
                  { 'field-group--error': $v.form.gender.$error && !this.status },
                ]"
            >
              <div :class="$style.gender">
                <label class="input-radio">
                  <input
                      type="radio"
                      name="sex"
                      v-model="form.gender"
                      :value="1"
                  >
                  <span class="input-radio__helper">
                    <span class="input-radio__text">M</span>
                  </span>
                </label>

                <label class="input-radio">
                  <input
                      type="radio"
                      name="sex"
                      v-model="form.gender"
                      :value="0"
                  >
                  <span class="input-radio__helper">
                    <span class="input-radio__text">V</span>
                  </span>
                </label>

                <label class="input-radio">
                  <input
                      type="radio"
                      name="sex"
                      v-model="form.gender"
                      :value="2"
                  >
                  <span class="input-radio__helper">
                    <span class="input-radio__text">X</span>
                  </span>
                </label>

                <label class="input-radio checkout__radio-button">
                  <input type="checkbox" v-model="form.trans" :checked="form.trans" :value="true">
                  <span class="input-radio__helper input-checkbox__helper">
                    <span class="input-radio__text">Trans</span>
                  </span>
                </label>
              </div>

              <template v-if="$v.form.gender.$error && !this.status">
            <span v-if="!$v.form.gender.required"
                  class="field-group__message field-group__message-error">
              Het veld is verplicht
            </span>
              </template>
            </FieldGroupComponent>

            <div>
              <div class="row">
                <div class="col-12 col-lg-6">
                  <field-group-component class="checkout__field-group"
                                         title="Voornaam"
                                         :class="{
                                    'field-group--error': $v.form.firstName.$error && !this.status
                                }">
                    <input
                        v-model.trim="$v.form.firstName.$model"
                        type="text"
                        placeholder="Voornaam"
                    >
                    <template v-if="$v.form.firstName.$error && !this.status">
              <span v-if="!$v.form.firstName.required"
                    class="field-group__message field-group__message-error">
                Het veld is verplicht
              </span>
                    </template>
                  </field-group-component>
                </div>
                <div class="col-12 col-lg-6">
                  <field-group-component class="checkout__field-group"
                                         title="Achternaam"
                                         :class="{
                                  'field-group--error': $v.form.lastName.$error && !this.status
                                }">
                    <input
                        v-model.trim="$v.form.lastName.$model" type="text" placeholder="Achternaam">
                    <template v-if="$v.form.lastName.$error && !this.status">
            <span v-if="!$v.form.lastName.required"
                  class="field-group__message field-group__message-error">
              Het veld is verplicht
            </span>
                    </template>
                  </field-group-component>
                </div>
              </div>
              <field-group-component class="checkout__field-group"
                                     title="Geboortedatum"
                                     :class="{
                                  'field-group--error': $v.form.birthdayDate.$error && !this.status
                                }">
                <input v-model.trim="$v.form.birthdayDate.$model"
                       type="text"
                       v-mask="'##/##/####'"
                       placeholder="DD/MM/JJJJ">

                <template v-if="$v.form.birthdayDate.$error && !this.status">
            <span v-if="!$v.form.birthdayDate.required"
                  class="field-group__message field-group__message-error">
              Het veld is verplicht
            </span>
                  <span v-if="!$v.form.birthdayDate.minDate"
                        class="field-group__message field-group__message-error">
              Ongeldige notatie voor geboortedatum
            </span>
                </template>
              </field-group-component>
              <field-group-component class="checkout__field-group"
                                     title="Email"
                                     :class="{
                                    'field-group--error': $v.form.email.$error && !this.status
                                }">
                <input
                    v-model.trim="$v.form.email.$model"
                    type="email"
                    placeholder="E-mailadres"
                >
                <template v-if="$v.form.email.$error && !this.status">
            <span v-if="!$v.form.email.required"
                  class="field-group__message field-group__message-error">
              Het veld is verplicht
            </span>
                  <span v-if="!$v.form.email.email"
                        class="field-group__message field-group__message-error">
              Ongeldig e-mailadres
            </span>
                </template>
              </field-group-component>
              <field-group-component title="Telefoonnummer"
                                     :class="{
                                    'field-group--error': $v.form.phoneNumber.$error && !this.status
                                }">
                <div class="d-flex form-group">
                  <input v-model.trim="$v.phoneCode.$model"
                         class="col-3 col-xl-2 mr-2"
                         v-mask="'+###'"
                         type="text">
                  <input v-model.trim="$v.form.phoneNumber.$model"
                         class="flex-fill"
                         v-mask="'### ### ####'"
                         type="text"
                         placeholder="061 234 5678"
                  >
                </div>
                <template v-if="$v.form.phoneNumber.$error && !this.status">
            <span v-if="!$v.form.phoneNumber.required"
                  class="field-group__message field-group__message-error">
              Het veld is verplicht
            </span>
                  <span v-if="!$v.form.phoneNumber.minLength"
                        class="field-group__message field-group__message-error">
              Ongeldig telefoonformaat
            </span>
                </template>
              </field-group-component>
            </div>
          </div>

          <div :class="$style.block">
            <Heading type="h2">Extra Check</Heading>
            <div>
              <p class="mt-0">
                Voor een juiste inschrijving hebben we het adres nodig waar jij staat
                <strong>ingeschreven</strong>.
              </p>
              <div class="row">
                <div class="col-4 col-md-6">
                  <field-group-component
                      title="Postcode"
                      :class="{
                          'field-group--error': $v.form.postcode.$error && !this.status
                        }">
                    <input
                        v-model.trim="$v.form.postcode.$model"
                        type="text"
                        v-mask="'#### XX'"
                        placeholder="1111 AA"
                    >
                    <template v-if="$v.form.postcode.$error && !this.status">
                    <span v-if="!$v.form.postcode.required"
                          class="field-group__message field-group__message-error">
                      Het veld is verplicht
                    </span>
                      <span v-if="!$v.form.postcode.regular"
                            class="field-group__message field-group__message-error">
                      Ongeldige postcode
                    </span>
                    </template>
                  </field-group-component>
                </div>
                <div class="col-4 col-md-3">
                  <field-group-component title="Huisnummer"
                                         :class="{
                                        'field-group--error': $v.form.number.$error && !this.status
                                        }">
                    <input
                        v-model.trim="$v.form.number.$model"
                        v-mask="'#####'"
                        type="text"
                        placeholder="Nr."
                    >
                    <template v-if="$v.form.number.$error && !this.status">
                    <span v-if="!$v.form.number.required"
                          class="field-group__message field-group__message-error">
                      Het veld is verplicht
                    </span>
                    </template>
                  </field-group-component>
                </div>
                <div class="col-4 col-md-3">
                  <field-group-component title="evt. Toevoeging">
                    <input
                        v-model="form.additional"
                        type="text"
                        placeholder="Toev."
                    >
                  </field-group-component>
                </div>
              </div>
              <field-group-component class="checkout__field-group mt-4"
                                     title="Straat"
                                     :class="{
                                        'field-group--error': $v.form.street.$error && !this.status
                                    }"
                                     v-if="this.form.isShowAddress"
              >
                <input
                    v-model.trim="$v.form.street.$model"
                    type="text"
                >
                <template v-if="$v.form.street.$error && !this.status">
                <span v-if="!$v.form.street.required"
                      class="field-group__message field-group__message-error">
                  Het veld is verplicht
                </span>
                </template>
              </field-group-component>
              <field-group-component
                  title="Stad"
                  :class="{
                                        'field-group--error': $v.form.city.$error && !this.status
                                    }"
                  v-if="this.form.isShowAddress"
              >
                <input v-model.trim="$v.form.city.$model"
                       type="text"
                >
                <template v-if="$v.form.city.$error && !this.status">
                <span v-if="!$v.form.city.required"
                      class="field-group__message field-group__message-error">
                  Het veld is verplicht
                </span>
                </template>
              </field-group-component>
            </div>
          </div>

          <div
              v-if="form.isHomeTest && this.form.isShowAddress"
              :class="$style.block"
          >
            <Heading type="h2">Bezorgadres</Heading>

            <AddressSearch
                v-if="isDeliveryAddressEdit"
                v-bind.sync="$v.form.delivery"
                :isShowDeliveryAddress="this.form.delivery.isShowAddress"
            />
            <AddressBox
                v-else
                button-text="Wijzig bezorgadres"
                @button-click="handleDeliveryChange"
            >
              <div v-for="item in deliveryAddressBoxArray" :key="item">{{ item }}</div>
            </AddressBox>
          </div>

        </div>

        <template v-if="catchErr">
            <span class="field-group__message field-group__message-error mb-4">
              An error occurred, please contact ziz@ziz.nl
            </span>
        </template>

        <div class="mt-4">
          <button
              class="btn btn-primary d-none d-md-inline-block relative overflow-hidden"
              type="button"
              @click="submit"
          >
            <span>Ik wil betalen</span>
            <span
                v-if="isFormSending"
                :class="$style.loader"
            >
                  <ZizLoader />
                </span>
          </button>
        </div>
      </form>
    </div>
    <div class="col-12 col-md-4 offset-md-1 mb-5 mb-md-0">
      <cart-component
          :show-product-type="true"
          :class="$style.sidebar"
          :is-donation-set="true"
          :is-donation-enable="isDonationEnable"
      >
        <!--:class="{'disabled': $v.$invalid}"
        :disabled="$v.$invalid"
        -->
        <template v-if="catchErr">
            <span class="field-group__message field-group__message-error mb-4">
              An error occurred, please contact ziz@ziz.nl
            </span>
        </template>
        <button
            class="btn btn-primary btn-block relative overflow-hidden"
            type="button"
            @click="submit"
        >
          <span>Ik wil betalen</span>
          <span
              v-if="isFormSending"
              :class="$style.loader"
          >
            <ZizLoader />
          </span>
        </button>
      </cart-component>
    </div>
  </div>
</template>

<script>
import { pick } from 'lodash';
import { validationMixin } from 'vuelidate';
import {
  required, email, minLength, helpers,
} from 'vuelidate/lib/validators';
import { withParams } from 'vuelidate/lib/withParamsBrowser';
import moment from 'moment';
import * as Sentry from '@sentry/vue';
import { mapGetters } from 'vuex';
import { Loader } from '@googlemaps/js-api-loader';
/* eslint-disable import/extensions */
import AddressSearch from '@/pages/Checkout/components/AddressSearch';
import Heading from '@/components/ui/Heading';
import RadioRows from '@/components/RadioRows';
import AddressBox from '@/components/ui/AddressBox';
import ZizLoader from '@/components/ui/Loader';
import FieldGroupComponent from './FieldGroupComponent.vue';
import CartComponent from './CartComponent.vue';
import getGtmProduct from '../helpers/gtmProduct';

const currentDate = moment(new Date()).startOf('day');
const minDate = withParams({ minDate: currentDate.format('DD/MM/YYYY') }, (value) => moment(value, 'DD/MM/YYYY', true).isSameOrBefore(currentDate));
const regular = helpers.regex('alpha', /^\d{4}\s?\w{2}$/);

const DELIVERY_FIELDS = {
  postcode: '',
  number: '',
  additional: '',
  street: '',
  city: '',
  isShowAddress: false,
};

const testingSiteMixin = {
  methods: {
    getAvailableLocations() {
      return [{
        id: 1,
        label: 'Ik kom langs in Den Haag',
        value: 1,
        icon: 'door-enter',
      }, {
        id: 2,
        label: 'Ik kom langs op Utrecht CS',
        value: 2,
        icon: 'door-enter',
      }, {
        id: 3,
        label: 'Thuisafname',
        info: this.infoText,
        value: 3,
        icon: 'package',
        disabled: this.IS_DISABLE_HOME_VISIT,
      }];
    },

    currentLocationValue(isHome) {
      if (this.IS_DISABLE_HOME_VISIT) {
        return 1;
      }

      if (typeof isHome === 'undefined') {
        return 0;
      }
      if (Number(this.howTest) === 2) {
        this.form.howTestText = 'UTR';
      }
      if (Number(this.howTest) === 1) {
        this.form.howTestText = 'HAAG';
      }
      return isHome ? 3 : Number(this.howTest);
    },
  },
};

export default {
  name: 'CheckoutFormComponent',
  components: {
    AddressBox,
    AddressSearch,
    FieldGroupComponent,
    CartComponent,
    Heading,
    RadioRows,
    ZizLoader,
  },
  props: {
    gMapsKey: {
      type: String,
      default: '',
    },
    isDonationEnable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNotEmptyCart: false,
      status: true,
      catchErr: false,
      isDeliveryAddressEdit: false,
      howTest: 1,
      form: {
        firstName: '',
        lastName: '',
        birthdayDate: '',
        email: '',
        phoneNumber: '',
        postcode: '',
        number: '',
        additional: '',
        street: '',
        city: '',
        howTestText: '',
        isHomeTest: false,
        gender: undefined,
        trans: false,
        delivery: DELIVERY_FIELDS,
      },
      isFormSending: false,
      phoneCode: '+31',
      geoCoder: null,
      // todo: get real product
      dummyProduct: {
        id: 0,
        risk: 0,
        title: 'Verzendkosten',
        type: null,
        cost: 4.95,
        careCost: 4.95,
        available: true,
        letter: null,
        key: null,
        embed: null,
      },
    };
  },
  computed: {
    ...mapGetters(['CART', 'IS_BLOOD_TEST_EXISTS', 'IS_DISABLE_HOME_VISIT', 'ANAMNESIS']),
    isBloodtestExist() {
      const bloodtestList = this.CART.filter((item) => item.type === 'bloedafname');
      const test = !!(this.IS_BLOOD_TEST_EXISTS || bloodtestList.length > 0);
      return test;
    },

    infoText() {
      return this.IS_DISABLE_HOME_VISIT ? 'Nog niet mogelijk bij PrEP' : `(+${this.dummyProduct.cost} verzendkosten)`;
    },

    deliveryAddressBoxArray() {
      const {
        city,
        street,
        number,
        additional,
        postcode,
      } = this.form.delivery;

      return [
        city,
        [street, street && number, street && additional].filter(Boolean).join(' '),
        postcode,
      ].filter(Boolean);
    },

    formValues() {
      if (this.isDeliveryAddressEdit) {
        return DELIVERY_FIELDS;
      }

      return pick(this.form, Object.keys(DELIVERY_FIELDS));
    },
  },
  mixins: [validationMixin, testingSiteMixin],
  validations() {
    if (this.form.isHomeTest) {
      return {
        form: {
          firstName: {
            required,
          },
          lastName: {
            required,
          },
          birthdayDate: {
            required,
            minDate,
          },
          email: {
            required,
            email,
          },
          phoneNumber: {
            required,
            minLength: minLength(11),
          },
          postcode: {
            required,
            regular,
          },
          number: {
            required,
          },
          street: {
            required,
          },
          city: {
            required,
          },
          gender: {
            required,
          },
          delivery: {
            postcode: {
              required,
              regular,
            },
            number: {
              required,
            },
            street: {
              required,
            },
            city: {
              required,
            },
            additional: {},
          },
        },
        phoneCode: {
          required,
        },
      };
    }

    return {
      form: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        birthdayDate: {
          required,
          minDate,
        },
        email: {
          required,
          email,
        },
        phoneNumber: {
          required,
          minLength: minLength(11),
        },
        postcode: {
          required,
          regular,
        },
        number: {
          required,
        },
        street: {
          required,
        },
        city: {
          required,
        },
        gender: {
          required,
        },
        isHomeTest: {
          required,
        },
      },
      phoneCode: {
        required,
      },
    };
  },

  created() {
    const isSingleDummyProductInCart = this.CART?.length === 1 && this.CART?.[0].id === 0;

    if (this.CART?.length === 0 || isSingleDummyProductInCart) {
      // Redirecting to consult, if empty card of card contains only Home Test Product
      window.location.replace('/consult');
      return;
    }

    if (isSingleDummyProductInCart) {
      this.$store.commit('REMOVE_FROM_CART', this.dummyProduct);
    }

    setTimeout(() => {
      this.isNotEmptyCart = true;
    }, 500);
  },

  async mounted() {
    const loader = new Loader({ apiKey: this.gMapsKey });
    const { Geocoder } = await loader.importLibrary('geocoding');
    this.geoCoder = new Geocoder();
    const user = this.$store.getters.USER;
    this.form.firstName = user.firstName;
    this.form.lastName = user.lastName;
    this.form.birthdayDate = user.birthdayDate;
    this.form.email = user.email;
    this.form.phoneNumber = user.phoneNumber;
    this.form.postcode = user.postcode;
    this.form.number = user.number;
    this.form.additional = user.additional;
    this.form.street = user.street;
    this.form.city = user.city;
    this.form.trans = user.trans;
    this.form.isHomeTest = user.isHomeTest;
    this.form.gender = user.gender;
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        items: this.CART.map((product, i) => getGtmProduct(product, i)),
      },
    });
  },
  methods: {
    /* eslint-disable no-param-reassign */
    getAddress(addressForm) {
      const { postcode } = addressForm;
      if (!this.geoCoder) {
        return null;
      }
      if (!/\d{4}\s[A-Z]{2}/i.test(postcode)) {
        return null;
      }
      addressForm.city = '';
      addressForm.street = '';
      this.geoCoder.geocode(
        {
          componentRestrictions: {
            postalCode: postcode,
            country: 'Netherlands',
          },
          region: 'NLD',
        },
        (results, status) => {
          if (status !== 'OK') {
            return false;
          }

          // eslint-disable-next-line max-len
          this.geoCoder.geocode({ latLng: results[0].geometry.location }, (decoded, cStatus) => {
            if (cStatus !== 'OK') {
              return false;
            }

            decoded.every((el) => {
              const ps = el.address_components.find((_el) => _el.types.some((ai) => ['postal_code'].includes(ai)));
              if (ps === undefined) {
                return true;
              }
              if (ps.long_name && ps.long_name === postcode.toUpperCase()) {
                const city = el.address_components.find((_el) => _el.types.includes('locality'));
                if (city.long_name) {
                  addressForm.city = city.long_name;
                  return false;
                }
              }
              return true;
            });

            let streets = [];
            decoded.forEach((el) => {
              const pIndx = el.address_components.findIndex((_el) => _el.types.some((ai) => ['postal_code'].includes(ai)));
              const index = el.address_components.findIndex((_el) => _el.types.some((ai) => ['street_address', 'route', 'premise'].includes(ai)));
              if (index < 0 || pIndx < 0) return;

              if (el.address_components[pIndx].long_name === postcode.toUpperCase()) {
                streets.push(el.address_components[index].long_name);
              }
            });

            streets = [...new Set(streets.filter(String))];
            if (streets.length === 1) {
              addressForm.street = streets.shift();
            }

            return false;
          });

          return false;
        },
      );

      addressForm.isShowAddress = true;

      return false;
    },
    /* eslint-enable no-param-reassign */
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.status = false;
        // Scroll to the error
        this.$nextTick(() => {
          const firstInvalidField = document.querySelector('.field-group--error');
          if (this.$v.form.isHomeTest?.$error) {
            this.$refs.testType.$el.parentElement.scrollIntoView({ behavior: 'auto' });
          } else if (firstInvalidField) {
            firstInvalidField.scrollIntoView({ behavior: 'auto' });
            firstInvalidField.querySelector('input').focus();
          }
        });
      } else {
        // do your submit logic here
        this.sendData();
        this.status = true;
      }
    },
    sendData() {
      // Prevent sending if there was no response
      if (this.isFormSending) {
        return;
      }

      if (this.phoneCode === '+31' && (this.form.phoneNumber)[0] === '0' && (this.form.phoneNumber)[1] === '6') {
        this.form.phoneNumber = this.form.phoneNumber.slice(1);
      }
      this.form.phone = `${this.phoneCode} ${this.form.phoneNumber}`;
      this.isFormSending = true;
      const user = { ...this.form };
      this.$store.commit('SET_USER', user);
      this.$http.post('/receive/checkout', {
        anamnesis: this.ANAMNESIS,
        data: this.$store.getters.USER,
        cart: this.$store.getters.CART,
        isCare: this.$store.getters.CARE,
        donation: this.$store.getters.CART_DONATION,
      })
        .then((data) => {
          window.location.href = data.data.redirect_uri;
          this.$store.commit('SET_REDIRECT_URI', data.data.redirect_uri);
        })
        .catch((err) => {
          Sentry.captureException(err);
          this.catchErr = true;
        })
        .finally(() => {
          this.isFormSending = false;
        });
    },
    handleHomeChange($event) {
      this.howTest = $event;
      this.form.isHomeTest = this.IS_DISABLE_HOME_VISIT ? false : parseInt($event, 10) === 3;
      if (this.form.isHomeTest) {
        this.form.howTestText = 'HOME';
      }
    },
    handleDeliveryChange() {
      this.isDeliveryAddressEdit = !this.isDeliveryAddressEdit;
    },
  },
  watch: {
    'form.postcode': {
      handler(newValue, oldValue) {
        if (oldValue === newValue) {
          return;
        }

        this.getAddress(this.form);
      },
      deep: true,
    },
    'form.delivery.postcode': {
      handler(newValue, oldValue) {
        if (!this.isDeliveryAddressEdit
            && (oldValue === newValue || this.form.postcode === this.form.delivery.postcode)) {
          return;
        }

        this.getAddress(this.form.delivery);
      },
    },
    'form.isHomeTest': {
      handler() {
        if (this.form.isHomeTest) {
          this.$store.commit('ADD_TO_CART', this.dummyProduct);
        } else {
          this.$store.commit('REMOVE_FROM_CART', this.dummyProduct);
        }
      },
      deep: true,
    },

    formValues: {
      handler(newVal) {
        this.form.delivery = newVal;
      },
    },

    isNotEmptyCart: {
      handler() {
        if (this.isNotEmptyCart) {
          // Remove preloader
          this.$parent.$refs.loading?.remove();
        }
      },
    },
  },
};
</script>

<style lang="scss" module scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.sidebar {
  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 24px;
  }
}

.gender {
  display: flex;
  gap: 24px;
  align-items: flex-start;
}

.loader {
  position: absolute;
  inset: 0;
  background-color: #00a664;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}
</style>
