<template>
  <Reviews v-if="reviews" :list="reviews" />
</template>

<script>
import { getReviews } from '@/helpers/googlePlaceDetails';

/* eslint-disable import/extensions */
import Reviews from '@/components/widgets/Reviews';

export default {
  name: 'HomeReviews',

  components: {
    Reviews,
  },

  props: {
    gMapsKey: String,
  },

  data: () => ({
    reviews: null,
  }),

  async created() {
    const reviews = await getReviews(this.gMapsKey, 4);

    if (reviews) {
      this.reviews = reviews;
    }
  },
};
</script>
