import { Loader } from '@googlemaps/js-api-loader';

// https://developers.google.com/maps/documentation/places/web-service/place-id#find-id
const PLACE_ID = 'ChIJJ2JMs7G3xUcR5XX_n7dwWaE';

const getPlaceDetails = async (apiKey, fields) => {
  if (!apiKey) {
    return null;
  }

  const loader = new Loader({ apiKey });
  const { PlacesService } = await loader.importLibrary('places');
  const places = new PlacesService(document.createElement('div'));

  return new Promise(((resolve, reject) => {
    places.getDetails(
      {
        placeId: PLACE_ID,
        fields,
      },
      (result, status) => (
        status === 'OK'
          ? resolve(result)
          : reject(status)
      ),
    );
  }));
};

const getReviews = async (apiKey, minRating) => {
  const { reviews } = await getPlaceDetails(apiKey, ['reviews']);

  if (!reviews) return [];
  return reviews.filter((review) => review.rating >= minRating);
};

export default getPlaceDetails;

export { getReviews };
